define("front/templates/jobmatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3c/pqfN",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-469JkVzilqlVJcH/out-709-colocated_template_processor/front/templates/jobmatch.hbs\"]]]],[2,\"\\n\"],[10,\"div\"],[14,1,\"jobs--header\"],[12],[2,\"\\n  \"],[8,\"header/main\",[],[[\"@stickyNav\",\"@fixed\"],[false,true]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"jobs-react/job-match-jobs-react\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\"]}",
    "meta": {
      "moduleName": "front/templates/jobmatch.hbs"
    }
  });

  _exports.default = _default;
});