define("front/templates/courses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8zjYXBdr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"_deprecate\"],[\"/tmp/broccoli-469JkVzilqlVJcH/out-709-colocated_template_processor/front/templates/courses/index.hbs\"]]]],[2,\"\\n\"],[10,\"div\"],[14,1,\"courses\"],[12],[2,\"\\n  \"],[8,\"header/main\",[],[[\"@stickyNav\"],[false]],null],[2,\"\\n  \"],[8,\"courses-react/courses-react\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"page-title\"]}",
    "meta": {
      "moduleName": "front/templates/courses/index.hbs"
    }
  });

  _exports.default = _default;
});